import { CoreAPI } from 'kiisu-api-types/core';
import { CustomerNew, CustomerStored } from 'kiisu-api-types/core.customers';
import { createRestypedAxios } from './base.api';

const coreAPI = createRestypedAxios<CoreAPI>();

export function getCustomers() {
  return coreAPI.get<'/core/v2/customers'>('/core/v2/customers');
}

export function postCustomer(data: CustomerNew) {
  return coreAPI.post<'/core/v2/customers'>('/core/v2/customers', data);
}

export function getCustomer(id: string) {
  return coreAPI.get<'/core/v2/customers/:id'>(`/core/v2/customers/${id}`);
}

export function patchCustomer(customer: CustomerStored) {
  return coreAPI.patch<'/core/v2/customers/:id'>(`/core/v2/customers/${customer.metadata.id}`, customer);
}
