import React from 'react';
import { Box, Heading, Text } from '@primer/react';
import AttachmentGallery from '../uploads/AttachmentGallery';
import { Data } from '../../api/api';
import DataColumn from '../DataColumn';
import { LocationStored } from 'kiisu-api-types/core.locations';
import { OrderStored } from 'kiisu-api-types/core.orders';
import { format } from 'date-fns';

interface OrderProps {
  order: OrderStored;
  location?: LocationStored;
  customer: string;
  files: Data[];
  handleError: any;
}

function OrderDataComponent(props: OrderProps) {
  const callTime = props.order.callTime ? format(props.order.callTime, 'dd.MM.yyyy HH:mm') : '';

  return (
    <Box py={2}>
      <Box display="flex" flexWrap={['wrap', 'nowrap']} mt={2} justifyContent="space-between">
        <Box display="flex" flexDirection="column" mr={2} width={[1, 0.4]}>
          <DataColumn label="Asukoht" value={props.location?.name} />
          <DataColumn label="Aadress" value={props.location?.address} />
          <DataColumn label="Klient" value={props.customer} />
        </Box>

        <Box display="flex" flexDirection="column" width={[1, 0.4]}>
          <DataColumn label="Tellija" value={props.order.orderer} />
          <DataColumn label="Telefon" value={props.order.phone} />
          <DataColumn label="Väljakutse" value={callTime} />
          {props.order.referenceNr && <DataColumn label="Kliendi viide" value={props.order.referenceNr} />}
        </Box>
      </Box>

      <Box mb={1} px={2}>
        <Box display="flex" flexDirection="column" mt={3}>
          <Heading sx={{ fontSize: 3 }}>Probleem</Heading>
          <Text ml={2} mt={2}>
            {props.order.description}
          </Text>

          {props.files.length > 0 && (
            <AttachmentGallery files={props.files.map((f) => f.j)} handleError={props.handleError} />
          )}
        </Box>

        {props.order.notes && (
          <Box display="flex" flexDirection="column" mt={3}>
            <Box display="flex" justifyContent="left" alignItems="center">
              <Box display="flex" flexDirection="column" width={1}>
                <Heading sx={{ fontSize: 2 }}>Märkused</Heading>
              </Box>
            </Box>

            <Text ml={2} mt={2}>
              {props.order.notes}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default OrderDataComponent;
