import React, { useState } from 'react';
import { addEntity, Data, deleteEntity } from '../../api/api';
import { deleteLocation, patchLocation, postLocation } from '../../api/api.locations';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, Text, TextInput } from '@primer/react';
import AttachmentGallery, { Attachment } from '../uploads/AttachmentGallery';
import FileUploader, { SavedFile } from '../uploads/FileUploader';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from '../CustomSelect';
import { ArrowLeftIcon } from '@primer/octicons-react';
import FormFooter from '../FormFooter';
import { LocationStored } from 'kiisu-api-types/core.locations';
import { ObjectMeta } from 'kiisu-api-types/common';
import { CustomerStored } from 'kiisu-api-types/core.customers';
import ConfirmDialog from '../ConfirmDialog';

interface LocationFormProps {
  entity: LocationStored | undefined;
  customers: CustomerStored[];
  files: Data[];
  users: Data[];
  setFiles: any;
  handleError: any;
}

interface FormValues {
  customerId: string;
  name: string;
  address: string;
  phone: string;
  workers: string[];
  metadata: ObjectMeta;
}

function LocationForm(props: LocationFormProps) {
  useDocumentTitle(['Asukohad', 'Asukoht']);

  const navigate = useNavigate();
  const [addedImages, setAddedImages] = useState<Attachment[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  async function resolvePromise(promise: Promise<any>) {
    return Promise.resolve(promise)
      .then((res) => {
        const additions = addedImages.map((i) => {
          const a = i;
          a.parentId = props.entity?.metadata.id || res.data.objectid;
          return addEntity('attachments', a);
        });

        return Promise.all(additions);
      })
      .then(() => {
        navigate('/locations');
      })
      .catch((error) => props.handleError(error));
  }

  function handleAddOrChange(form: FormValues) {
    if (props.entity && props.entity.metadata.id) {
      return resolvePromise(patchLocation(form));
    } else {
      return resolvePromise(postLocation(form));
    }
  }

  async function handleSavedFiles(files: SavedFile[]) {
    const data = files.map((f) => {
      return {
        parentId: '',
        filename: f.originalname,
        hash: f.filename,
        mimeType: f.mimetype
      };
    });
    setAddedImages([...addedImages, ...data]);
  }

  async function handleFileRemove(hash: string) {
    return deleteEntity('attachments', hash, 1)
      .then(() => {
        setAddedImages(addedImages.filter((i) => i.hash !== hash));
        props.setFiles(props.files.filter((f) => f.j.hash !== hash));
      })
      .catch((error) => props.handleError(error));
  }

  const workerOptions = props.users
    .filter((u) => u.j.role === 'worker')
    .map((u) => ({ label: u.j.firstName + ' ' + u.j.lastName, value: u.i }));

  const galleryFiles = [...addedImages, ...props.files.map((f) => f.j)];

  function getUserName(id: string) {
    const user = props.users.find((u) => u.i === id);

    if (user) {
      return `${user.j.firstName} ${user.j.lastName}`;
    }

    return '';
  }

  const defaultValues = {
    customerId: props.entity?.customerId,
    name: props.entity?.name,
    address: props.entity?.address,
    phone: props.entity?.phone,
    workers: props.entity?.workers ? props.entity.workers : [],
    metadata: props.entity?.metadata
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<FormValues>({ defaultValues: defaultValues });

  const customerOptions = props.customers.map((customer) => ({
    label: customer.name,
    value: customer.metadata.id
  }));

  async function handleDelete(id: string, version: number) {
    deleteLocation(id, version)
      .then(() => navigate('/locations'))
      .catch((error) => {
        setShowConfirmation(false);
        props.handleError(error);
      });
  }

  return (
    <form onSubmit={handleSubmit(handleAddOrChange)} onChange={() => props.handleError(undefined)}>
      <Box sx={{ display: 'grid', gap: 4, mb: 2 }}>
        <FormControl>
          <FormControl.Label htmlFor="customer-select">Klient</FormControl.Label>
          <Controller
            name="customerId"
            control={control}
            rules={{ required: { value: true, message: 'Määra klient' } }}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                inputId="customer-select"
                placeholder="Vali klient..."
                options={customerOptions}
                value={customerOptions.filter((customer) => value === customer.value)}
                onChange={(event: any) => onChange(event!.value)}
                aria-errormessage={errors.customerId?.message}
                aria-invalid={!!errors.customerId}
              />
            )}
          />
          {errors.customerId && (
            <FormControl.Validation variant="error">{errors.customerId.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Nimetus</FormControl.Label>
          <TextInput
            block={true}
            {...register('name', {
              required: {
                value: true,
                message: 'Lisa asukoha nimetus'
              }
            })}></TextInput>
          {errors.name && <FormControl.Validation variant="error">{errors.name.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label>Aadress</FormControl.Label>
          <TextInput
            block={true}
            {...register('address', {
              required: {
                value: true,
                message: 'Lisa asukoha aadress'
              }
            })}></TextInput>
          {errors.address && <FormControl.Validation variant="error">{errors.address.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label>Telefon</FormControl.Label>
          <TextInput block={true} {...register('phone')}></TextInput>
        </FormControl>

        <FormControl>
          <FormControl.Label htmlFor="worker-select">Töömehed</FormControl.Label>
          <Controller
            name="workers"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                inputId="worker-select"
                isMulti={true}
                name="workers"
                placeholder="Vali töömees..."
                options={workerOptions}
                value={value.map((worker) => {
                  return { label: getUserName(worker), value: worker };
                })}
                onChange={(event) => onChange(event!.map((e: any) => e.value))}
              />
            )}
          />
        </FormControl>

        {galleryFiles.length > 0 && (
          <AttachmentGallery
            files={galleryFiles}
            handleError={props.handleError}
            handleFileRemove={handleFileRemove}
            newWindow={true}
          />
        )}
        <Box display="flex" justifyContent="space-between" mt="1">
          <FileUploader
            handleError={props.handleError}
            handleSavedFiles={handleSavedFiles}
            clearErrors={() => props.handleError(undefined)}
          />

          <FormFooter>
            <Button onClick={() => navigate('/locations')} leadingVisual={ArrowLeftIcon}>
              Tagasi
            </Button>

            <Button variant="primary" type="submit">
              {props.entity ? 'Salvesta' : 'Lisa'}
            </Button>

            {props.entity && (
              <Button variant="danger" onClick={() => setShowConfirmation(true)}>
                Kustuta
              </Button>
            )}
          </FormFooter>
        </Box>
      </Box>

      <ConfirmDialog
        showConfirm={showConfirmation}
        closeModal={() => setShowConfirmation(false)}
        onClick={() => handleDelete(props.entity!.metadata.id, props.entity!.metadata.resourceVersion)}>
        <Text as="p" fontWeight="bold">
          Kas oled kindel, et soovid selle asukoha kustutada?
        </Text>
      </ConfirmDialog>
    </form>
  );
}

export default LocationForm;
