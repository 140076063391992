import React, { useEffect, useState } from 'react';
import ListContent from '../components/ListContent';
import ContentContainer from '../components/layout/ContentContainer';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { Link } from 'react-router-dom';
import PageHead from '../components/layout/PageHead';
import CustomLoader from '../components/CustomLoader';
import { getCustomers } from '../api/api.customers';

import { checkAPIError } from '../services/ErrorService';
import { Banner } from '@primer/react/experimental';

interface CustomerRow {
  id: string;
  name: string;
}

function Customers() {
  useDocumentTitle(['Kliendid']);

  const columns = [
    {
      header: 'Nimi',
      field: 'name',
      sortBy: 'alphanumeric',
      width: 'auto',
      rowHeader: true,
      renderCell: (row: any) => {
        return <Link to={'/customers/' + row.id}>{row.name}</Link>;
      }
    }
  ];

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const [customerRows, setCustomerRows] = useState<CustomerRow[]>();
  const [apiError, setApiError] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCustomers()
      .then((response) =>
        setCustomerRows(
          response.data.items.map((customer) => ({
            id: customer.metadata.id,
            name: customer.name
          }))
        )
      )
      .catch((error) => handleError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <ContentContainer xlarge>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title="Kliendid" add="/customers/add" />
      {loading && <CustomLoader />}
      {!loading && (
        <ListContent
          rows={customerRows ? customerRows : []}
          columns={columns}
          blankslateText="Ühtegi klienti ei ole veel lisatud."
        />
      )}
    </ContentContainer>
  );
}

export default Customers;
