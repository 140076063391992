import React, { useEffect, useState } from 'react';
import ListContent from '../components/ListContent';
import ContentContainer from '../components/layout/ContentContainer';
import { getCurrentUserRole } from '../services/CurrentUserService';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { getLocations } from '../api/api.locations';
import { getCustomers } from '../api/api.customers';
import { Link } from 'react-router-dom';
import PageHead from '../components/layout/PageHead';
import CustomLoader from '../components/CustomLoader';

import { checkAPIError } from '../services/ErrorService';
import { Banner } from '@primer/react/experimental';
import { CustomerStored } from 'kiisu-api-types/core.customers';

interface LocationRow {
  name: string;
  customer: string;
  address: string | undefined;
  phone: string | undefined;
}

function Locations() {
  useDocumentTitle(['Asukohad']);

  const columns: any[] = [
    {
      header: 'Nimetus',
      field: 'name',
      sortBy: 'alphanumeric',
      width: 'auto',
      rowHeader: true,
      renderCell: (row: any) => {
        return <Link to={'/locations/' + row.id}>{row.name}</Link>;
      }
    },
    { header: 'Klient', field: 'customer', sortBy: 'alphanumeric', hideOnSmall: true, width: 'auto' },
    { header: 'Aadress', field: 'address', width: 'auto' },
    { header: 'Telefon', field: 'phone', hideOnSmall: true, width: 'auto' }
  ];

  const [customers, setCustomers] = useState<CustomerStored[]>();
  const [locationRows, setLocationRows] = useState<LocationRow[]>();
  const [apiError, setApiError] = useState<string>();
  const [loading, setLoading] = useState(true);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    getCustomers()
      .then((response) => setCustomers(response.data.items))
      .catch((error) => {
        setCustomers([]);
        handleError(error);
      });
  }, []);

  useEffect(() => {
    function getCustomerName(customerId: string) {
      const customer = customers?.find((c) => c.metadata.id === customerId);
      return customer ? customer.name : '';
    }

    if (customers) {
      getLocations(true)
        .then((response) => {
          setLocationRows(
            response.data.items.map((location) => ({
              id: location.metadata.id,
              name: location.name,
              customer: getCustomerName(location.customerId),
              address: location.address,
              phone: location.phone
            }))
          );
        })
        .catch((error) => handleError(error))
        .finally(() => setLoading(false));
    }
  }, [customers]);

  return (
    <ContentContainer xlarge>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title="Asukohad" add={getCurrentUserRole() === 'admin' ? '/locations/add' : undefined} />
      {loading && <CustomLoader />}
      {!loading && (
        <ListContent
          rows={locationRows ? locationRows : []}
          columns={columns}
          blankslateText="Ühtegi asukohta ei ole veel lisatud."
        />
      )}
    </ContentContainer>
  );
}

export default Locations;
