import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import { CloseDialog } from '../../types/common';
import { DrivingDataStored } from 'kiisu-api-types/core.work-data';
import { getDrivingData, patchDrivingData, postDrivingData } from '../../api/api.work-data';
import { ContainerMd } from '../layout/container';
import { useForm } from 'react-hook-form';
import { ObjectMeta } from 'kiisu-api-types/common';
import FormFooter from '../FormFooter';
import { checkAPIError } from '../../services/ErrorService';
import { Banner } from '@primer/react/experimental';

interface FormValues {
  orderId: string;
  drivingHours?: string;
  drivingKmTo?: number;
  drivingKmBack?: number;
  metadata: ObjectMeta;
}

function DrivingForm(props: {
  preloadedValues?: DrivingDataStored;
  orderId: string;
  closeModal: CloseDialog;
  handleError: any;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: props.preloadedValues ? props.preloadedValues : { orderId: props.orderId }
  });

  async function onSubmit(form: FormValues) {
    if (form.drivingKmTo) {
      form.drivingKmTo = Number(form.drivingKmTo?.toString().replace(/,/g, '.'));
    }
    if (form.drivingKmBack) {
      form.drivingKmBack = Number(form.drivingKmBack?.toString().replace(/,/g, '.'));
    }
    if (form.drivingHours) {
      form.drivingHours = form.drivingHours.replace(/,/g, '.');
    }
    if (form.drivingHours && form.drivingHours.endsWith('.')) {
      form.drivingHours = form.drivingHours.slice(0, -1);
    }

    if (props.preloadedValues) {
      return patchDrivingData(form)
        .then(() => props.closeModal(true))
        .catch((error) => props.handleError(error));
    }

    return postDrivingData(form)
      .then(() => props.closeModal(true))
      .catch((error) => props.handleError(error));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={() => props.handleError(undefined)}>
      <Box sx={{ display: 'grid', gap: 3, py: 3 }}>
        <FormControl>
          <FormControl.Label>Sinna (km)</FormControl.Label>
          <TextInput
            block
            {...register('drivingKmTo', {
              pattern: {
                value: /^[0-9]*[.,]?[0-9]*$/,
                message: 'Sisesta km numbrites'
              }
            })}
          />
          {errors.drivingKmTo && (
            <FormControl.Validation variant="error">{errors.drivingKmTo.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Tagasi (km)</FormControl.Label>
          <TextInput
            block
            {...register('drivingKmBack', {
              pattern: {
                value: /^[0-9]*[.,]?[0-9]*$/,
                message: 'Sisesta km numbrites'
              }
            })}
          />
          {errors.drivingKmBack && (
            <FormControl.Validation variant="error">{errors.drivingKmBack.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Sõidutunnid</FormControl.Label>
          <TextInput
            block
            {...register('drivingHours', {
              pattern: {
                value: /^[0-9.,]+[ ]?(t|min)?[.]?$/,
                message: 'Sõidutunnid ei vasta formaadile "number (ühik)"'
              }
            })}
          />
          {errors.drivingHours && (
            <FormControl.Validation variant="error">{errors.drivingHours.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormFooter>
          <Button onClick={() => props.closeModal(false)}>Katkesta</Button>

          <Button variant="primary" type="submit">
            {props.preloadedValues ? 'Muuda' : 'Lisa'}
          </Button>
        </FormFooter>
      </Box>
    </form>
  );
}

function DrivingChangeForm(props: { id?: string; orderId: string; closeModal: CloseDialog }) {
  const [entity, setEntity] = useState<DrivingDataStored>();
  const [apiError, setApiError] = useState<string>();

  function handleError(error: any) {
    setApiError(checkAPIError(error));
  }

  useEffect(() => {
    if (props.id) {
      getDrivingData(props.id)
        .then((res) => setEntity(res.data))
        .catch((error) => handleError(error));
    }
    //eslint-disable-next-line
  }, [props.id]);

  return (
    <Box as="main">
      <ContainerMd sx={{ px: [16, 24, 32], my: 3 }}>
        {apiError && <Banner style={{ padding: '0.75rem 0.5rem' }} variant="critical" title={apiError} />}
        {(!props.id || entity) && (
          <DrivingForm
            preloadedValues={entity}
            orderId={props.orderId}
            closeModal={props.closeModal}
            handleError={handleError}
          />
        )}
      </ContainerMd>
    </Box>
  );
}

export default DrivingChangeForm;
