import { OrderStored } from 'kiisu-api-types/core.orders';
import { formatDateTimeValue } from '../utils/DateTimeUtils';

export interface OrderRow {
  id: string;
  name: string;
  referenceNr: string;
  callTime: string;
  inserted?: string;
  startedWorking?: string;
  completed?: string;
  status: string;
}

export function constructOrderRow(
  order: OrderStored,
  locations?: Record<string, string>,
  statuses?: Record<string, string>
): OrderRow {
  return {
    id: order.metadata.id,
    name: locations ? locations[order.locationId] : '',
    referenceNr: order.referenceNr!,
    callTime: formatDateTimeValue(order.callTime),
    inserted: formatDateTimeValue(order.metadata.creationTimestamp),
    startedWorking: formatDateTimeValue(order.startedWorking),
    completed: formatDateTimeValue(order.completed),
    status: statuses ? statuses[order.status] : ''
  };
}
