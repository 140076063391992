import { CoreAPI } from 'kiisu-api-types/core';
import { LocationNew, LocationStored } from 'kiisu-api-types/core.locations';
import { createRestypedAxios } from './base.api';

const coreAPI = createRestypedAxios<CoreAPI>();

export function getLocations(onlyActive?: boolean) {
  return coreAPI.get<'/core/v2/locations'>('/core/v2/locations', {
    params: {
      onlyActive: onlyActive
    }
  });
}

export function deleteLocation(id: string, version: number) {
  return coreAPI.delete<'/core/v2/locations/:id'>(`/core/v2/locations/${id}`, {
    params: {
      version: version
    }
  });
}

export function postLocation(data: LocationNew) {
  return coreAPI.post<'/core/v2/locations'>('/core/v2/locations', data);
}

export function getLocation(id: string) {
  return coreAPI.get<'/core/v2/locations/:id'>(`/core/v2/locations/${id}`);
}

export function patchLocation(location: LocationStored) {
  return coreAPI.patch<'/core/v2/locations/:id'>(`/core/v2/locations/${location.metadata.id}`, location);
}
