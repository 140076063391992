import React, { useEffect, useState } from 'react';
import { ToggleSwitch } from '@primer/react';
import {
  getPushManagerSubscription,
  getServerApplicationKey,
  initNotifications,
  notificationsSupported,
  subscribe,
  unsubscribe
} from '../services/NotificationService';

function NotificationsToggleSwitch(props: { labelId: string }) {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isOn, setIsOn] = React.useState(true);
  const [serverKey, setServerKey] = useState<string>();

  useEffect(() => {
    const init = () => {
      getServerApplicationKey()
        .then((key) => {
          setServerKey(key);
          return initNotifications();
        })
        .then((enabled) => {
          setIsOn(enabled);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Notifications initialization failed!', error);
          setLoading(false);
          setIsOn(false);
          setIsDisabled(true);
        });
    };
    init();
  }, []);

  const handleSwitchChange = async (on: boolean) => {
    const existing = await getPushManagerSubscription();

    if (on && !existing) {
      await subscribe(serverKey).catch((error) => {
        console.error('Subscription failed!', error);
        setIsOn(!on);
      });
    }

    if (!on && !!existing) {
      await unsubscribe().catch((error) => {
        console.error('Unsubscribing failed!', error);
        setIsOn(!on);
      });
    }
  };

  return notificationsSupported()
    ? !loading && (
        <ToggleSwitch
          id="notifications-switch"
          size="small"
          aria-labelledby={props.labelId}
          onClick={() => setIsOn(!isOn)}
          onChange={handleSwitchChange}
          checked={isOn}
          loading={loading}
          disabled={isDisabled}
        />
      )
    : null;
}

export default NotificationsToggleSwitch;
