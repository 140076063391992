import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { postMarkup } from '../../api/api.spares';
import { Box, Button, Dialog, FormControl, TextInput } from '@primer/react';
import FormFooter from '../FormFooter';
import { CloseDialog } from '../../types/common';
import { checkAPIError } from '../../services/ErrorService';
import { Banner } from '@primer/react/experimental';

interface MarkupFormValues {
  name: string;
  rate: number;
}

function MarkupForm(props: { closeDialog: any; handleError: any }) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<MarkupFormValues>();

  async function onSubmit(form: MarkupFormValues) {
    postMarkup(form)
      .then(() => props.closeDialog(true))
      .catch((error) => props.handleError(error));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'grid', gap: 3, pt: 4 }}>
        <FormControl>
          <FormControl.Label>Nimetus</FormControl.Label>
          <TextInput
            block
            {...register('name', { required: { value: true, message: 'Määra juurdehindluse nimetus' } })}
          />
          {errors.name && <FormControl.Validation variant="error">{errors.name.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label>Protsent</FormControl.Label>
          <TextInput
            block
            trailingVisual="&#37;"
            {...register('rate', {
              required: { value: true, message: 'Määra juurdehindlus protsent' },
              pattern: { value: /^[0-9]*$/, message: 'Sisesta protsent täisarvuna' }
            })}
          />
          {errors.rate && <FormControl.Validation variant="error">{errors.rate.message}</FormControl.Validation>}
        </FormControl>

        <FormFooter>
          <Button onClick={() => props.closeDialog()}>Sulge</Button>

          <Button variant="primary" type="submit">
            Lisa
          </Button>
        </FormFooter>
      </Box>
    </form>
  );
}

function MarkupDialog(props: { isOpen: boolean; closeDialog: CloseDialog }) {
  const [apiError, setApiError] = useState<string>();

  function handleError(error: any) {
    setApiError(checkAPIError(error));
  }

  return (
    <Dialog isOpen={props.isOpen} onDismiss={props.closeDialog}>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <Dialog.Header>Lisa juurdehindlus</Dialog.Header>
      <Box p={3}>
        <MarkupForm closeDialog={props.closeDialog} handleError={handleError} />
      </Box>
    </Dialog>
  );
}

export default MarkupDialog;
