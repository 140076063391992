import React, { useEffect, useState } from 'react';
import { Box, Label, Link, Octicon, Text, useTheme } from '@primer/react';
import { Box as BorderBox, BoxRow } from '../box';
import {
  DrivingDataStored,
  StockItemStored,
  UsedSpareStored,
  Worker,
  WorkProgressStored
} from 'kiisu-api-types/core.work-data';
import {
  deleteDrivingData,
  deleteStockItem,
  deleteUsedSpare,
  deleteWorkProgress,
  getDrivings,
  getStockItems,
  getUsedSpares,
  getWorkings
} from '../../api/api.work-data';
import { H4 } from '../utilities/typography';
import { PencilIcon, XIcon } from '@primer/octicons-react';
import { formatDateTimeDuration } from '../../utils/DateTimeUtils';
import { SpareStored } from 'kiisu-api-types/core.spares';
import { getSpare } from '../../api/api.spares';

type OpenWorkModal = (type: string, id?: string) => void;
type WorkDataEntity = WorkProgressStored | DrivingDataStored | StockItemStored | UsedSpareStored;

function WorkProgressList(props: {
  orderId: string;
  canChange: boolean;
  openWorkModal?: OpenWorkModal;
  reload: boolean;
  handleError: any;
}) {
  const [lines, setLines] = useState<WorkDataEntity[]>([]);
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    Promise.all([
      getWorkings(props.orderId),
      getDrivings(props.orderId),
      getStockItems(props.orderId),
      getUsedSpares(props.orderId)
    ])
      .then((res) => {
        const resulLines = [...res[0].data.items, ...res[1].data.items, ...res[2].data.items, ...res[3].data.items];
        setLines(resulLines.sort(sortDates));
      })
      .catch((error) => props.handleError(error));
    //eslint-disable-next-line
  }, [props.orderId, props.reload, shouldReload]);

  function sortDates(a: WorkDataEntity, b: WorkDataEntity): number {
    return Date.parse(a.metadata.creationTimestamp) - Date.parse(b.metadata.creationTimestamp);
  }

  function instanceOfWP(entity: any): entity is WorkProgressStored {
    return 'workStart' in entity;
  }

  function instanceOfDD(entity: any): entity is DrivingDataStored {
    return 'drivingHours' in entity;
  }

  function instanceOfSI(entity: any): entity is StockItemStored {
    return 'name' in entity;
  }

  function instanceOfUS(entity: any): entity is UsedSpareStored {
    return 'spareId' in entity;
  }

  return (
    <Box>
      {lines.length > 0 && (
        <BorderBox>
          {lines.map((row: WorkDataEntity) => {
            return (
              <BoxRow key={row.metadata.id}>
                {instanceOfWP(row) && (
                  <WorkProgressBox
                    entity={row}
                    canChange={props.canChange}
                    onEdit={props.openWorkModal}
                    reload={() => setShouldReload(!shouldReload)}
                    handleError={props.handleError}
                  />
                )}
                {instanceOfDD(row) && (
                  <DrivingDataBox
                    entity={row}
                    canChange={props.canChange}
                    onEdit={props.openWorkModal}
                    reload={() => setShouldReload(!shouldReload)}
                    handleError={props.handleError}
                  />
                )}
                {instanceOfSI(row) && (
                  <StockItemBox
                    entity={row}
                    canChange={props.canChange}
                    onEdit={props.openWorkModal}
                    reload={() => setShouldReload(!shouldReload)}
                    handleError={props.handleError}
                  />
                )}
                {instanceOfUS(row) && (
                  <UsedSpareBox
                    entity={row}
                    canChange={props.canChange}
                    onEdit={props.openWorkModal}
                    reload={() => setShouldReload(!shouldReload)}
                    handleError={props.handleError}
                  />
                )}
              </BoxRow>
            );
          })}
        </BorderBox>
      )}
    </Box>
  );
}

function WorkProgressBox(props: {
  entity: WorkProgressStored;
  canChange?: boolean;
  onEdit?: OpenWorkModal;
  reload: any;
  handleError: any;
}) {
  const { theme } = useTheme();

  function deleteEntity() {
    deleteWorkProgress(props.entity.metadata.id, props.entity.metadata.resourceVersion)
      .then(() => props.reload())
      .catch((error) => props.handleError(error));
  }

  return (
    <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gridGap={3}>
      <Box display="flex" flex="auto" gridGap={3} flexDirection="column">
        <H4 sx={{ color: theme!.colors.fg.default }}>{props.entity.description}</H4>

        <Box display="flex" flexWrap={['wrap', 'nowrap']} gridGap={2} ml={3} sx={{ color: theme!.colors.fg.muted }}>
          <Text>{formatDateTimeDuration(props.entity.workStart, props.entity.workEnd)}</Text>
          {props.entity.workers && (
            <Text>{props.entity.workers.map((w: Worker) => w.firstName + ' ' + w.lastName).join(', ')}</Text>
          )}
        </Box>
      </Box>

      {props.canChange && props.onEdit && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gridGap={2}
          flexWrap="nowrap">
          <Link onClick={() => props.onEdit!('WorkProgress', props.entity.metadata.id)}>
            <Octicon icon={PencilIcon} size={24} color="success.fg" />
          </Link>
          <Link onClick={() => deleteEntity()}>
            <Octicon icon={XIcon} size={24} color="danger.fg" />
          </Link>
        </Box>
      )}
    </Box>
  );
}

function DrivingDataBox(props: {
  entity: DrivingDataStored;
  canChange?: boolean;
  onEdit?: OpenWorkModal;
  reload: any;
  handleError: any;
}) {
  const { theme } = useTheme();

  function hasSuffix(value: string) {
    const endsWithSuffix = /(t|min)?$/;
    return value.match(endsWithSuffix);
  }

  function getTitle() {
    let title = 'Sõiduinfo';

    if (props.entity.drivingHours) {
      title = title + ' ' + props.entity.drivingHours;
    }
    if (props.entity.drivingHours && !hasSuffix(props.entity.drivingHours)) {
      title = title + ' t';
    }

    return title;
  }

  function deleteEntity() {
    deleteDrivingData(props.entity.metadata.id, props.entity.metadata.resourceVersion)
      .then(() => props.reload())
      .catch((error) => props.handleError(error));
  }

  return (
    <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gridGap={3}>
      <Box display="flex" flex="auto" gridGap={3} flexDirection="column">
        <H4 sx={{ color: theme!.colors.fg.default }}>{getTitle()}</H4>
        <Box display="flex" flexWrap={['wrap', 'nowrap']} gridGap={2} ml={3} sx={{ color: theme!.colors.fg.muted }}>
          <Text whiteSpace="nowrap">Sinna: {props.entity.drivingKmTo} km</Text>
          <Text whiteSpace="nowrap">Tagasi: {props.entity.drivingKmBack} km</Text>
        </Box>
      </Box>

      {props.canChange && props.onEdit && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gridGap={2}
          flexWrap="nowrap">
          <Link onClick={() => props.onEdit!('DrivingData', props.entity.metadata.id)}>
            <Octicon icon={PencilIcon} size={24} color="success.fg" />
          </Link>
          <Link onClick={() => deleteEntity()}>
            <Octicon icon={XIcon} size={24} color="danger.fg" />
          </Link>
        </Box>
      )}
    </Box>
  );
}

function StockItemBox(props: {
  entity: StockItemStored;
  canChange?: boolean;
  onEdit?: OpenWorkModal;
  reload: any;
  handleError: any;
}) {
  const { theme } = useTheme();

  function deleteEntity() {
    deleteStockItem(props.entity.metadata.id, props.entity.metadata.resourceVersion)
      .then(() => props.reload())
      .catch((error) => props.handleError(error));
  }

  return (
    <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gridGap={3}>
      <Box display="flex" flex="auto" gridGap={3} flexDirection="column">
        <Box display="flex" gridGap={2} alignItems="center" flexWrap="wrap">
          <H4 sx={{ color: theme!.colors.fg.default }}>{props.entity.name}</H4>
          {props.entity.code && <Label variant="secondary">{props.entity.code}</Label>}
        </Box>

        <Box display="flex" flexWrap={['wrap', 'nowrap']} gridGap={3} ml={3} sx={{ color: theme!.colors.fg.muted }}>
          <Text whiteSpace="nowrap">
            {props.entity.amount} {props.entity.unit}
          </Text>
        </Box>
      </Box>

      {props.canChange && props.onEdit && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gridGap={2}
          flexWrap="nowrap">
          <Link onClick={() => props.onEdit!('StockItem', props.entity.metadata.id)}>
            <Octicon icon={PencilIcon} size={24} color="success.fg" />
          </Link>
          <Link onClick={() => deleteEntity()}>
            <Octicon icon={XIcon} size={24} color="danger.fg" />
          </Link>
        </Box>
      )}
    </Box>
  );
}

function UsedSpareBox(props: {
  entity: UsedSpareStored;
  canChange?: boolean;
  onEdit?: OpenWorkModal;
  reload: any;
  handleError: any;
}) {
  const { theme } = useTheme();
  const [spare, setSpare] = useState<SpareStored>();

  useEffect(() => {
    getSpare(props.entity.spareId)
      .then((res) => setSpare(res.data))
      .catch((error) => props.handleError(error));
    //eslint-disable-next-line
  }, [props.entity.spareId]);

  function deleteEntity() {
    deleteUsedSpare(props.entity.metadata.id, props.entity.metadata.resourceVersion)
      .then(() => props.reload())
      .catch((error) => props.handleError(error));
  }

  return (
    <Box>
      {spare && (
        <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gridGap={3}>
          <Box display="flex" flex="auto" gridGap={3} flexDirection="column">
            <Box display="flex" gridGap={2} alignItems="center" flexWrap="wrap">
              <H4 sx={{ color: theme!.colors.fg.default }}>{spare?.name}</H4>
              {spare.code && <Label variant="secondary">{spare.code}</Label>}
            </Box>

            <Box display="flex" flexWrap={['wrap', 'nowrap']} gridGap={3} ml={3} sx={{ color: theme!.colors.fg.muted }}>
              <Text whiteSpace="nowrap">
                {props.entity.amount} {spare.unit}
              </Text>
            </Box>
          </Box>
          {props.canChange && props.onEdit && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              gridGap={2}
              flexWrap="nowrap">
              <Link onClick={() => props.onEdit!('UsedSpare', props.entity.metadata.id)}>
                <Octicon icon={PencilIcon} size={24} color="success.fg" />
              </Link>
              <Link onClick={() => deleteEntity()}>
                <Octicon icon={XIcon} size={24} color="danger.fg" />
              </Link>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default WorkProgressList;
