import React from 'react';
import { Data } from '../../api/api';
import AttachmentGallery from '../uploads/AttachmentGallery';
import DataColumn from '../DataColumn';
import { LocationStored } from 'kiisu-api-types/core.locations';
import { CustomerStored } from 'kiisu-api-types/core.customers';

function LocationView(props: {
  entity: LocationStored;
  customers: CustomerStored[];
  files: Data[];
  users: Data[];
  handleError: any;
}) {
  function getClientName(): string {
    const found = props.customers.find((c) => c.metadata.id === props.entity.customerId);
    return found ? found.name : '';
  }

  function getUserName(id: string) {
    const user = props.users.find((u) => u.i === id);
    return user ? `${user.j.firstName} ${user.j.lastName}` : '';
  }

  return (
    <>
      <DataColumn label="Klient" value={getClientName()} />

      <DataColumn label="Aadress" value={props.entity.address} />

      <DataColumn label="Telefon" value={props.entity.phone} />

      {props.entity.workers && (
        <DataColumn label="Töömehed" value={props.entity.workers.map((w: string) => getUserName(w)).join(', ')} />
      )}

      {props.files.length > 0 && (
        <AttachmentGallery files={props.files.map((f) => f.j)} handleError={props.handleError} newWindow={true} />
      )}
    </>
  );
}

export default LocationView;
