import React, { useEffect, useState } from 'react';
import ListContent from '../components/ListContent';
import ContentContainer from '../components/layout/ContentContainer';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { getEntities } from '../api/api';
import { Link } from 'react-router-dom';
import PageHead from '../components/layout/PageHead';
import CustomLoader from '../components/CustomLoader';
import { checkAPIError } from '../services/ErrorService';
import { Banner } from '@primer/react/experimental';

interface UserRow {
  id: string;
  name: string;
  username: string;
  phone: string;
  role?: string;
}

function Users() {
  useDocumentTitle(['Kasutajad']);

  const [userRows, setUserRows] = useState<UserRow[]>();
  const [apiError, setApiError] = useState<string>();
  const [loading, setLoading] = useState(true);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    Promise.all([getEntities('users'), getEntities('user-roles')])
      .then(([users, userRoles]) =>
        setUserRows(
          users.data.map((row: any) => ({
            id: row.i,
            name: row.j.firstName + ' ' + row.j.lastName,
            username: row.j.username,
            phone: row.j.phone,
            role: userRoles.data.find((role) => role.j.role === row.j.role)?.j.text
          }))
        )
      )
      .catch((error) => handleError(error))
      .finally(() => setLoading(false));
  }, []);

  const columns: any[] = [
    {
      header: 'Nimi',
      field: 'name',
      sortBy: 'alphanumeric',
      rowHeader: true,
      renderCell: (row: any) => {
        return <Link to={'/users/' + row.id}>{row.name}</Link>;
      }
    },
    { header: 'Kasutajanimi', field: 'username', sortBy: 'alphanumeric', hideOnSmall: true },
    { header: 'Telefon', field: 'phone', hideOnSmall: true },
    { header: 'Roll', field: 'role', width: 'growCollapse', sortBy: 'alphanumeric' }
  ];

  return (
    <ContentContainer xlarge>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title="Kasutajad" add="/users/add" />
      {loading && <CustomLoader />}
      {!loading && (
        <ListContent
          rows={userRows ? userRows : []}
          columns={columns}
          blankslateText="Ühtegi kasutajat ei ole veel lisatud."
        />
      )}
    </ContentContainer>
  );
}

export default Users;
