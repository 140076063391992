import React, { useEffect, useState } from 'react';
import { getLocation } from '../api/api.locations';
import { checkAPIError } from '../services/ErrorService';
import { getCurrentUserRole } from '../services/CurrentUserService';
import LocationView from '../components/locations/LocationView';
import LocationForm from '../components/locations/LocationForm';
import ContentContainer from '../components/layout/ContentContainer';
import { useParams } from 'react-router';
import { LocationStored } from 'kiisu-api-types/core.locations';
import { Data, getEntities, getEntitiesByReference } from '../api/api';
import CustomLoader from '../components/CustomLoader';
import PageHead from '../components/layout/PageHead';
import { Banner } from '@primer/react/experimental';
import { CustomerStored } from 'kiisu-api-types/core.customers';
import { getCustomers } from '../api/api.customers';

function LocationContainer() {
  const { id } = useParams();
  const [apiError, setApiError] = useState<string>();
  const [entity, setEntity] = useState<LocationStored>();
  const [customers, setCustomers] = useState<CustomerStored[]>();
  const [files, setFiles] = useState<Data[]>([]);
  const [users, setUsers] = useState<Data[]>();

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (id) {
      getLocation(id)
        .then((response) => {
          setEntity(response.data);
          return getEntitiesByReference('attachments', 'parentId', id);
        })
        .then((filesResponse) => setFiles(filesResponse.data))
        .catch((error) => handleError(error));
    }
    // eslint-disable-next-line
  }, [id, !!entity, files.length]);

  useEffect(() => {
    getEntities('users')
      .then((response) => setUsers(response.data))
      .catch((error) => {
        setUsers([]);
        handleError(error);
      });
    getCustomers()
      .then((response) => setCustomers(response.data.items))
      .catch((error) => {
        setCustomers([]);
        handleError(error);
      });
  }, []);

  function getContainerTitle() {
    if (getCurrentUserRole() === 'admin') {
      return id ? 'Muuda asukoha andmeid' : 'Lisa uus asukoht';
    } else if (id && entity) {
      return entity.name;
    }

    return 'Asukoht';
  }

  return (
    <>
      {!users || !customers ? (
        <CustomLoader />
      ) : (
        (!id || (entity && entity.metadata.id)) && (
          <ContentContainer>
            {apiError && (
              <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
            )}
            <PageHead title={getContainerTitle()} />
            {getCurrentUserRole() === 'admin' && (
              <LocationForm
                entity={entity}
                customers={customers}
                files={files}
                users={users}
                setFiles={setFiles}
                handleError={handleError}
              />
            )}
            {entity && getCurrentUserRole() === 'worker' && (
              <LocationView
                entity={entity}
                customers={customers}
                files={files}
                users={users}
                handleError={handleError}
              />
            )}
          </ContentContainer>
        )
      )}
    </>
  );
}

export default LocationContainer;
