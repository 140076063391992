import { useEffect, useRef, useState } from 'react';
import { getOrders } from '../../api/api.orders';
import { OrderStored } from 'kiisu-api-types/core.orders';
import InfiniteScroll from 'react-infinite-scroller';
import CustomLoader from '../CustomLoader';
import { DocumentTitleTranslation, useDocumentTitle } from '../../hooks/useDocumentTitle';
import ListContent from '../ListContent';
import { Link } from 'react-router-dom';
import { constructOrderRow, OrderRow } from '../../types/orderRow';

interface TabViewProps {
  status: string;
  statuses?: Record<string, string>;
  locations?: Record<string, string>;
  handleError: any;
  orderBy: string;
}

const titleTranslations: DocumentTitleTranslation = {
  pending: 'Ootel',
  undone: 'Pooleli',
  done: 'Tehtud',
  all: 'Kõik'
};

export const OrdersColumns = [
  {
    header: 'Number',
    field: 'id',
    renderCell: (row: any) => {
      return <Link to={'/orders/' + row.id}>{row.id.substring(0, 8)}</Link>;
    },
    width: 'auto'
  },
  { header: 'Asukoht', field: 'name', width: 'auto' },
  { header: 'Kliendi viide', field: 'referenceNr', hideOnSmall: true, width: 'auto' },
  { header: 'Sisestatud', field: 'inserted', hideOnSmall: true },
  { header: 'Väljakutse', field: 'callTime', hideOnSmall: true },
  { header: 'Töösse võetud', field: 'startedWorking', hideOnSmall: true },
  { header: 'Lõpetatud', field: 'completed', hideOnSmall: true },
  { header: 'Seis', field: 'status', width: 'auto' }
];

function OrderTabView(props: TabViewProps) {
  useDocumentTitle(['Tellimused'], titleTranslations);
  const LIMIT = 50;

  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [rows, setRows] = useState<OrderRow[]>([]);
  const [remainingItemCount, setRemainingItemCount] = useState<number>();
  const eTagMap = useRef(new Map<number, string>());
  const timer = useRef<number>();

  useEffect(() => {
    loadFirstSetOfData(true).finally(() => setLoading(false));

    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
    // eslint-disable-next-line
  }, []);

  async function loadFirstSetOfData(initial: boolean) {
    return getOrders(props.status, LIMIT, 0, eTagMap.current.get(0))
      .then((result) => {
        const newETag = result.headers['etag'];
        eTagMap.current.set(0, newETag);

        const orderRows = processOrders(result.data.items);
        props.handleError(undefined);

        if (initial) {
          setRows(orderRows);
          setRemainingItemCount(result.data.metadata?.remainingItemCount);
        } else {
          setRows((prevRows) => {
            const merged = [...prevRows];
            orderRows.reverse().forEach((or) => (merged.some((r) => r.id === or.id) ? null : merged.unshift(or)));
            return merged;
          });
          props.handleError(undefined);
        }

        timer.current = window.setTimeout(() => {
          loadFirstSetOfData(false);
        }, 300000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 304) {
          timer.current = window.setTimeout(() => {
            loadFirstSetOfData(false);
          }, 300000);
          return;
        }

        props.handleError(error);
      });
  }

  function processOrders(orders: OrderStored[]) {
    return orders.map((order) => constructOrderRow(order, props.locations, props.statuses));
  }

  async function loadMoreData() {
    try {
      const nextOffset = offset + LIMIT;
      const currentETag = eTagMap.current.get(nextOffset);

      const result = await getOrders(props.status, LIMIT, nextOffset, currentETag);

      const newETag = result.headers['etag'];

      if (newETag && newETag !== currentETag) {
        eTagMap.current.set(nextOffset, newETag);
      }

      const orderRows = processOrders(result.data.items);

      setRemainingItemCount(result.data.metadata?.remainingItemCount);
      setRows((prevRows) => [...prevRows, ...orderRows]);
      setOffset((prevOffset) => prevOffset + LIMIT);
    } catch (error: any) {
      if (error.response && error.response.status === 304) {
        return;
      }

      props.handleError(error);
    }
  }

  return (
    <>
      {!loading && (
        <InfiniteScroll
          loadMore={loadMoreData}
          initialLoad={false}
          hasMore={!!remainingItemCount && remainingItemCount > 0}
          loader={<CustomLoader key={offset} />}>
          <ListContent
            rows={rows}
            columns={OrdersColumns}
            blankslateText="Ühtegi selle staatusega tellimust ei ole veel lisatud."
          />
        </InfiniteScroll>
      )}
    </>
  );
}

export default OrderTabView;
