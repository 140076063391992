import { logout } from '../api/api';

export function checkAPIError(error: any): string | undefined {
  if (!error) {
    return;
  }

  if (error.response) {
    switch (error.response.status) {
      case 400:
        if (error.response.data?.message && error.response.data.message === 'Spare with the same name already exists') {
          return 'Sellise nimega varuosa on juba olemas.';
        }
        if (error.response.data?.message && error.response.data.message.endsWith('Invalid username or password')) {
          return 'Vale kasutajanimi või salasõna.';
        }
        if (
          error.response.data?.message &&
          error.response.data.message.startsWith('Unique constraint violation') &&
          error.request.responseURL.endsWith('users')
        ) {
          return 'Sellise kasutajanimega kasutaja on juba olemas.';
        }
        break;
      case 401:
        doLogout();
        break;
      case 403:
        return 'Kasutajal puudub õigus päringu teostamiseks.';
      case 404:
        return 'Päritud objekti ei leitud.';
      case 409:
        return 'Salvestamine ebaõnnestus, andmed on vahepeal muutunud. Värskenda lehte.';
    }
  }

  return 'Päringu teostamisel ilmnes viga.';
}

function doLogout() {
  logout().then(() => {
    localStorage.clear();
    window.location.assign('/login');
  });
}
