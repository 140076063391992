import React, { useEffect, useState } from 'react';
import { addEntity, Data, deleteEntity, getEntitiesByReference } from '../api/api';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import FileUploader, { SavedFile } from '../components/uploads/FileUploader';
import AttachmentGallery, { Attachment } from '../components/uploads/AttachmentGallery';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import SingleDateTimePicker from '../components/SingleDateTimePicker';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from '../components/CustomSelect';
import { ArrowLeftIcon } from '@primer/octicons-react';
import FormFooter from '../components/FormFooter';
import ContentContainer from '../components/layout/ContentContainer';
import PageHead from '../components/layout/PageHead';
import { getLocations } from '../api/api.locations';
import { LocationStored } from 'kiisu-api-types/core.locations';
import { getOrder, patchOrder, postOrder } from '../api/api.orders';
import { OrderStored } from 'kiisu-api-types/core.orders';
import { ObjectMeta } from 'kiisu-api-types/common';
import { checkAPIError } from '../services/ErrorService';
import { Banner } from '@primer/react/experimental';

interface FormValues {
  locationId: string;
  referenceNr: string;
  phone: string;
  orderer: string;
  callTime: string;
  description: string;
  notes: string;
  status: string;
  metadata: ObjectMeta;
  address: string;
}

interface OrderInitialFormProps {
  entity: OrderStored | undefined;
  handleError: any;
  images: Attachment[];
  setImages: any;
  locations: LocationStored[];
  files: Data[];
  setFiles: any;
}

function OrderInitialForm(props: OrderInitialFormProps) {
  const navigate = useNavigate();

  async function resolvePromise(promise: Promise<any>) {
    return Promise.resolve(promise)
      .then((res) => {
        const additions = props.images.map((i: any) => {
          const a = i;
          a.parentId = props.entity?.metadata.id || res.data.objectid;
          return addEntity('attachments', a);
        });

        return Promise.all(additions);
      })
      .then(() => navigate('/orders'))
      .catch((error) => props.handleError(error));
  }

  function handleAddOrChange(form: FormValues) {
    if (props.entity && props.entity.metadata.id) {
      const order = { ...props.entity, ...form };
      return resolvePromise(patchOrder(order));
    }
    form.status = 'pending';
    return resolvePromise(postOrder(form));
  }

  async function handleSavedFiles(files: SavedFile[]) {
    const data = files.map((f) => {
      return {
        parentId: '',
        filename: f.originalname,
        hash: f.filename,
        mimeType: f.mimetype
      };
    });
    props.setImages([...props.images, ...data]);
  }

  async function handleFileRemove(hash: string) {
    return deleteEntity('attachments', hash, 1)
      .then(() => {
        const newImages = props.images.filter((i: any) => i.hash !== hash);
        props.setImages(newImages);
        props.setFiles(props.files.filter((f: any) => f.j.hash !== hash));
      })
      .catch((error) => props.handleError(error));
  }

  const defaultValues = {
    referenceNr: props.entity?.referenceNr,
    locationId: props.entity?.locationId,
    phone: props.entity?.phone,
    orderer: props.entity?.orderer,
    callTime: props.entity?.callTime,
    description: props.entity?.description,
    notes: props.entity?.notes,
    metadata: props.entity?.metadata,
    status: props.entity?.status
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getFieldState,
    watch
  } = useForm<FormValues>({ defaultValues: defaultValues });

  const watchedLocation = watch('locationId');

  useEffect(() => {
    const selected = props.locations.find((location: any) => location.metadata.id === watchedLocation);

    if (!getFieldState('phone').isTouched && selected?.phone) {
      setValue('phone', selected.phone);
    }

    if (selected?.address) {
      setValue('address', selected.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedLocation, props.locations]);

  const locationOptions = props.locations.map((l: any) => ({
    label: l.name,
    value: l.metadata.id
  }));

  const galleryFiles = [...props.images, ...props.files.map((f: any) => f.j)];

  function handleDateChange(date: Date | null) {
    let value;

    if (date) {
      value = date.toISOString();
    } else {
      value = '';
    }
    return value;
  }

  return (
    <form onSubmit={handleSubmit(handleAddOrChange)} onChange={() => props.handleError(undefined)}>
      <Box sx={{ display: 'grid', gap: 4, mb: 2 }}>
        <FormControl>
          <FormControl.Label>Kliendi viide</FormControl.Label>
          <TextInput block {...register('referenceNr')} />
        </FormControl>

        <FormControl>
          <FormControl.Label htmlFor="locationId">Asukoht</FormControl.Label>
          <Controller
            name="locationId"
            control={control}
            rules={{ required: { value: true, message: 'Määra asukoht' } }}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                inputId="locationId"
                placeholder="Vali asukoht..."
                options={locationOptions}
                value={locationOptions.filter((location: any) => value === location.value)}
                onChange={(event: any) => onChange(event.value)}
                aria-errormessage={errors.locationId?.message}
                aria-invalid={!!errors.locationId}
              />
            )}
          />
          {errors.locationId && (
            <FormControl.Validation variant="error">{errors.locationId.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl disabled>
          <FormControl.Label>Aadress</FormControl.Label>
          <TextInput block {...register('address')} />
        </FormControl>

        <FormControl>
          <FormControl.Label>Telefon</FormControl.Label>
          <TextInput block {...register('phone')} />
        </FormControl>

        <FormControl>
          <FormControl.Label>Tellija</FormControl.Label>
          <TextInput
            block
            {...register('orderer', {
              required: {
                value: true,
                message: 'Sisesta tellija andmed'
              }
            })}
          />
          {errors.orderer && <FormControl.Validation variant="error">{errors.orderer.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label htmlFor="callTime">Väljakutse aeg</FormControl.Label>
          <Controller
            name="callTime"
            control={control}
            rules={{
              required: { value: true, message: 'Vali väljakutse aeg' }
            }}
            render={({ field: { onChange, value } }) => (
              <SingleDateTimePicker
                name="callTime"
                id="callTime"
                value={value}
                onChange={(d) => onChange(handleDateChange(d))}
                hasError={!!errors.callTime}
              />
            )}
          />
          {errors.callTime && (
            <FormControl.Validation variant="error">{errors.callTime.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Probleem/Tellimus</FormControl.Label>
          <TextInput
            block
            {...register('description', {
              required: {
                value: true,
                message: 'Kirjelda tellija probleemi'
              }
            })}
          />
          {errors.description && (
            <FormControl.Validation variant="error">{errors.description.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Märkused</FormControl.Label>
          <TextInput block {...register('notes')} />
        </FormControl>

        {galleryFiles.length > 0 && (
          <AttachmentGallery
            files={galleryFiles}
            handleError={props.handleError}
            handleFileRemove={handleFileRemove}
            newWindow={true}
          />
        )}

        <Box display="flex" justifyContent="space-between" mt="1">
          <FileUploader
            handleError={props.handleError}
            handleSavedFiles={handleSavedFiles}
            clearErrors={() => props.handleError(undefined)}
          />

          <FormFooter>
            <Button onClick={() => navigate(-1)} leadingVisual={ArrowLeftIcon}>
              Tagasi
            </Button>

            <Button variant="primary" type="submit">
              {props.entity && props.entity.metadata.id ? 'Salvesta' : 'Lisa'}
            </Button>
          </FormFooter>
        </Box>
      </Box>
    </form>
  );
}

function OrderInitialPage() {
  useDocumentTitle(['Tellimused', 'Tellimus']);

  const { id } = useParams();
  const [apiError, setApiError] = useState<string>();
  const [entity, setEntity] = useState<OrderStored>();
  const [addedImages, setAddedImages] = useState<Attachment[]>([]);
  const [locations, setLocations] = useState<LocationStored[]>([]);
  const [files, setFiles] = useState<Data[]>([]);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    getLocations(true)
      .then((response) => setLocations(response.data.items))
      .catch((error) => handleError(error));
  }, []);

  useEffect(() => {
    if (id) {
      getOrder(id)
        .then((resp) => setEntity(resp.data))
        .catch((error) => handleError(error));
      getEntitiesByReference('attachments', 'parentId', id)
        .then((resp) => setFiles(resp.data))
        .catch((error) => handleError(error));
    }
    // eslint-disable-next-line
  }, [id, !!entity, files.length]);

  return (
    <ContentContainer>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title={entity ? 'Muuda tellimuse andmeid' : 'Lisa uus tellimus'} />
      {(!id || (entity && entity.metadata.id)) && (
        <OrderInitialForm
          entity={entity}
          handleError={handleError}
          images={addedImages}
          setImages={setAddedImages}
          locations={locations}
          files={files}
          setFiles={setFiles}
        />
      )}
    </ContentContainer>
  );
}

export default OrderInitialPage;
