import React, { useEffect, useState } from 'react';
import { Data, getEntitiesByReference } from '../api/api';
import { checkAPIError } from '../services/ErrorService';
import ContentContainer from '../components/layout/ContentContainer';
import OrderChangeForm from '../components/orders/OrderChangeForm';
import { Box, Label } from '@primer/react';
import OrderFullView from '../components/orders/OrderFullView';
import OrderActivityLog from '../components/orders/OrderActivityLog';
import { H2 } from '../components/utilities/typography';
import PageHead from '../components/layout/PageHead';
import { getLocation } from '../api/api.locations';
import { LocationStored } from 'kiisu-api-types/core.locations';
import { getOrder } from '../api/api.orders';
import { OrderStored } from 'kiisu-api-types/core.orders';
import OrderPreview from '../components/orders/OrderPreview';
import { useParams } from 'react-router';
import { Banner } from '@primer/react/experimental';
import { getCustomer } from '../api/api.customers';

function OrdersContainer() {
  const { id } = useParams();

  const [order, setOrder] = useState<OrderStored>();
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState<LocationStored>();
  const [reload, setReload] = useState(false);
  const [files, setFiles] = useState<Data[]>([]);
  const [apiError, setApiError] = useState<string>();
  const [customer, setCustomer] = useState('');

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    loadOrderData(true).finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [id]);

  async function loadOrderData(initial?: boolean) {
    if (id) {
      return getOrder(id)
        .then((res) => {
          setOrder(res.data);
          setReload(initial ? reload : !reload);
          return loadLocationData(res.data.locationId);
        })
        .then(() => getEntitiesByReference('attachments', 'parentId', id))
        .then((res) => setFiles(res.data))
        .catch((error) => handleError(error));
    }
  }

  function loadLocationData(locationId: string) {
    if (!locationId) {
      return Promise.resolve();
    }

    return getLocation(locationId)
      .then((res) => {
        setLocation(res.data);
        return getCustomer(res.data.customerId);
      })
      .then((res) => setCustomer(res.data.name))
      .catch((error) => handleError(error));
  }

  function showOrderPreview() {
    return order && order.status && order.status === 'pending';
  }

  function showOrderChangeForm() {
    return order && order.status && order.status === 'inProgress';
  }

  function showFullOrderView() {
    return order && order.status && order.status !== 'pending' && order.status !== 'inProgress';
  }

  function getTitle() {
    return (
      <>
        Tellimus {order && order.metadata && order.metadata.id && order.metadata.id.substring(0, 8)}
        {order?.status === 'canceled' && (
          <Label sx={{ m: 1, verticalAlign: 'top' }} variant="attention">
            Tühistatud
          </Label>
        )}
      </>
    );
  }

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column" width={[1, 1, 1, 0.8]}>
        <ContentContainer xlarge>
          {apiError && (
            <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
          )}
          <PageHead title={getTitle()} />
          {order && !loading && (
            <>
              {showOrderPreview() && (
                <OrderPreview
                  order={order}
                  location={location}
                  customer={customer}
                  files={files}
                  reloadOrder={loadOrderData}
                  handleError={handleError}
                />
              )}

              {showOrderChangeForm() && (
                <OrderChangeForm
                  order={order}
                  location={location}
                  customer={customer}
                  files={files}
                  reloadOrder={loadOrderData}
                  reloadLocation={loadLocationData}
                  handleError={handleError}
                />
              )}
              {showFullOrderView() && (
                <OrderFullView
                  order={order}
                  location={location}
                  customer={customer}
                  files={files}
                  reloadOrder={loadOrderData}
                  handleError={handleError}
                />
              )}
            </>
          )}
        </ContentContainer>
      </Box>

      <Box
        flexDirection="column"
        display={['none', 'none', 'none', 'none', 'flex']}
        width={0.3}
        pr={4}
        sx={{ bg: 'canvas.default', pt: 3 }}>
        <H2>Tegevuste ajalugu</H2>
        {order && order.metadata && order.metadata.id && (
          <OrderActivityLog orderId={order.metadata.id} update={reload} />
        )}
      </Box>
    </Box>
  );
}

export default OrdersContainer;
