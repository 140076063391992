import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './SingleDateTimePicker.css';
import { et } from 'date-fns/locale/et';
import DatePickerActions from './DatePickerActions';

type OnChange = (date: Date | null) => void;

function SingleDateTimePicker(props: { name: string; id: string; value: string; onChange: OnChange; onBlur?: any; hasError?: boolean }) {
  const datepickerRef = useRef<DatePicker>(null);

  const onChange = (date: Date) => {
    props.onChange(date);
    datepickerRef.current?.setOpen(false);
  };

  return (
    <DatePicker
      ref={datepickerRef}
      name={props.name}
      id={props.id}
      selected={props.value ? new Date(props.value) : null}
      showTimeSelect
      isClearable={true}
      locale={et}
      timeFormat="HH:mm"
      timeCaption="aeg"
      timeIntervals={5}
      dateFormat="dd.MM.yyyy HH:mm"
      className={props.hasError ? 'form-control input-block invalid-date' : 'form-control input-block'}
      calendarClassName="single-date-time-picker-calendar"
      monthClassName={(name) => 'custom-month-' + name.toISOString()}
      onChange={props.onChange}
      onBlur={props.onBlur}
      popperPlacement={'bottom-start'}
      popperModifiers={[
        {
          name: 'arrow',
          fn(state: any) {
            if (state?.middlewareData?.arrow) {
              state.middlewareData.arrow.x = 160;
            }
            return state;
          }
        }
      ]}>
      <DatePickerActions onChange={onChange} actions={[{ label: 'Täna', date: new Date() }]} />
    </DatePicker>
  );
}

export default SingleDateTimePicker;
